import REQ from "../app/REQ";
import {classifyByProp, genMap, upWFoundById, invMapOf, collectLinked, classify} from "../utils/xyhUtils"
import siteCard from '../cards/site.vue'
import addDocPrjtStaffDlg from "@/dialg/addDocPrjtStaffDlg.vue";
import editDocDlg from '@/dialg/editDocDlg.vue'
import {mapGetters, mapState} from "vuex";
import {DOC_ROLE, DOC_STATE, DOC_TYPE} from "@/consts"
import docOptions from "./docOptions"
import Options from "@/Options";
import editDocPrjtDlg from "@/dialg/editDocPrjtDlg.vue";
import editAnnexDlg from "@/dialg/editAnnexDlg.vue"
import rmDocDlg from "@/doc/rmDocDlg.vue";
import editDocDeptDlg from "@/doc/editDocDeptDlg.vue";
import selDeptDlg from "@/doc/selDeptDlg.vue";
import {wordViewUrl} from "@/doc/consts"
import dlgFoot from "@/generalComs/dlgFoot.vue"
import elDragDialog from "@/directive/el-drag-dialog";
import addDocStaffDlg from "@/doc/addDocStaffDlg.vue"

const {DocPathsSMap} = docOptions
const {DocTypesSMap, DocStatesSMap} = Options

export default {
	name: "docPrjt",
	components:{ siteCard,addDocPrjtStaffDlg, editDocDlg, editDocPrjtDlg, editAnnexDlg, rmDocDlg, editDocDeptDlg, selDeptDlg, dlgFoot, addDocStaffDlg, },
	directives: { elDragDialog },
	props: ['pid'],

	data() {
		return {
			docPaths:DocPathsSMap,
			prjt: [],
			docs:[],
			annexes:[],
			depts:[],
			docDept: null,
			annexe:null,

			amIPM:false,
			amIAuthor:false,
			amIPrjtStaff:false,
			ourStaffs: [],
			prjtStaffs:[],
			oldDoc:null,
			typeN:null,
			type:null,
			checks: {},
			isBatch:false,
			allDocs:[],
			filterWord1:'',
			filterWord2:'',
			order:{
				num:true,
			},
			sortingProperty:'',
			docStatus:'',
			docType:'unAnnex',
			dept:'',
			planRmDoc: {},
			roleDlg:false,
			role:{name:'', name_E:'', des:'', des_E:'', deptId:'',},
			roles: [],
			roleId:'',
			myPrjts:[],
			targetPrjtId:'',
			switchPrjt:false,
			roleN:'authorN',
			filterOfRole:{
				authorN:'作者',
				auditorN:'审阅者',
				// translatorN:'翻译',
				allStaffN:'全部',
			},
			draft: 0x10,
			remarking: 0x20,
			fixed: 0x30,
			issued: 0x40,
			docCensus:{},
		};
	},

	computed: {
		...mapState({
			orgId: ({ curOrg }) => curOrg?.id,
			myId: ({curStaff}) => curStaff?.id,
		}),
		...mapGetters(['amIAdmin']),

		displayDocs(){
			let {filterWord2, docStatus, docType, order, docs, dept, roleN} = this
			if(filterWord2.length>0) docs = this.docs.filter(it=>
				it.name.toLowerCase().includes(filterWord2) ||
				it.num.toLowerCase().includes(filterWord2) ||
				(it[roleN] && it[roleN].toLowerCase().includes(filterWord2))
			)
			if(docStatus) docs = docs.filter(it=>it.state == docStatus)
			if(docType) docs = docs.filter(it => docType == 'unAnnex' ? it.type != DOC_TYPE.annex:it.type == docType)
			if(dept) docs = docs.filter(it=>it.deptId == dept.id)
			for(let i in order){
				if(order[i]) {
					docs.sort((doc1, doc2) => doc1[i].toLowerCase().localeCompare(doc2[i].toLowerCase()))
				}else {
					docs.sort((doc1, doc2) => doc2[i].toLowerCase().localeCompare(doc1[i].toLowerCase()))
				}
			}
			return docs
		},

		allSeled() {return this.displayDocs.every(it=>this.checks[it.id]);},

		isIndeterminate() { return  !(this.allSeled || this.displayDocs.every(it=>!this.checks[it.id]))},
	},

	created() {
		this.getDocPrjt()
	},

	methods: {
		getDocPrjt() {
			let {pid} = this
			this.req2Data(REQ.docPrjt, pid, null,({prjtStaffs,staffs,docs,tplts,docStaffs,prjt,depts,}) => {
				prjt.sopTpltN = tplts.find(it=>it.id == prjt.sopTpltId) ? tplts.find(it=>it.id == prjt.sopTpltId).name : ''
				prjt.policyTpltN = tplts.find(it=>it.id == prjt.policyTpltId) ? tplts.find(it=>it.id == prjt.policyTpltId).name : ''
				prjt.manualTpltN = tplts.find(it=>it.id == prjt.manualTpltId) ? tplts.find(it=>it.id == prjt.manualTpltId).name : ''
				upWFoundById(docStaffs, staffs, "staffId", (docStaff, staff) => docStaff.staffN = staff.name)
				let docIdOfStaffs = classifyByProp(docStaffs, 'docId')
				docs.forEach(doc=>{
					let staffs = docIdOfStaffs[doc.id]
					if(staffs){
						let authors = staffs.filter(it=>it.role == DOC_ROLE.author)
						let auditors = staffs.filter(it=>it.role == DOC_ROLE.auditor)
						let translators = staffs.filter(it=>it.role == DOC_ROLE.translator)
						let allStaffs = Object.values(genMap(staffs, 'staffId'))
						doc.amIStaff = staffs.find(it=>it.staffId == this.myId) ? true:false
						doc.amIAuthor = authors.find(it=>it.staffId == this.myId) ? true:false
						doc.authorN = authors.map(it=>it.staffN).join('、')
						doc.auditorN = auditors.map(it=>it.staffN).join('、')
						doc.translatorN = translators.map(it=>it.staffN).join('、')
						doc.allStaffN = allStaffs.map(it=>it.staffN).join('、')
					}
				})
				upWFoundById(prjtStaffs,staffs,'staffId',(prjtStaff,staff)=>prjtStaff.prjtStaffN = staff.name)
				this.amIPrjtStaff = prjtStaffs.find(it=>it.staffId == this.myId) ? true:false
				this.amIPM = prjtStaffs.find(it=>it.role == DOC_ROLE.PM && it.staffId == this.myId) ? true:false
				this.amIAuthor = prjtStaffs.find(it=>it.role == DOC_ROLE.author && it.staffId == this.myId) ? true:false
				upWFoundById(docs,tplts,'tpltId',(doc,tplt)=>doc.tpltN = tplt.name)
				upWFoundById(docs, depts, 'deptId', (doc, dept)=> doc.deptN = dept.name)
				this.depts = collectLinked(depts, 'parentId', 'childrens')
				docs.forEach(it=> {
					if(!it.type) it.type = DOC_TYPE.sop
					if(!it.state) it.state = DOC_STATE.remarking
					it.canPreview = it.tpltId || (it.type == DOC_TYPE.sop && prjt.sopTpltId) || (it.type == DOC_TYPE.policy && prjt.policyTpltId) || (it.type == DOC_TYPE.manual && prjt.manualTpltId)
					it.url = ((it.type == DOC_TYPE.annex || it.type == DOC_TYPE.file) && it.file) ? it.file:XyhConf.HglSHost + '/' + REQ.previewDoc + '?id=' + it.id + '&orgId=' + this.orgId + '&t=' + new Date().getTime()
				})
				this.docCensus = this.createDocCensus(docs)
				this.allDocs = docs
			})
		},

		getDocCrRolesOfPrjt(){
			let {pid} = this
			this.req2Data(REQ.docCrRolesOfPrjt, {prjtId: pid}, {roles:'roles'})
		},

		addDoc(type){
			this.oldDoc = null
			this.typeN = DocTypesSMap[type]
			this.type = type
			this.showDlg('editDocDlg')
		},

		editRole(role, rm){
			if(rm) {
				this.confirm('该操作将会删除此文档中使用角色，确认吗？',()=>{
					this.req2Data(REQ.rmDocCrRole, {id:role.id}, {}, ()=>this.getDocCrRolesOfPrjt())
				})
				return
			}
			this.roleId = role ? role.id:''
			if(role) {
				this.role = {name: role.name, name_E: role.name_E, des: role.des, des_E: role.des_E, deptId: role.deptId}
			}else {
				this.role = {name: '', name_E: '', des: '', des_E: '', deptId: ''}
			}
			this.roleDlg = true
		},

		editDocCrRole(){
			let {role, roleId, pid, roles} = this
			if(roleId) roles = roles.filter(it=>it.id != roleId)
			if (this.chk2msg(role.name || role.name_E, '中文名或英文名至少存在一个',
				(!role.name || roles.every(it=>it.name != role.name && it.name_E != role.name)),
				'中文名不能和其他角色的中英文相同',
				(!role.name_E || roles.every(it=>it.name != role.name && it.name_E != role.name_E)),
				'英文名不能和其他角色的中英文相同')) return
			if(roleId){
				this.req2Data(REQ.upDocCrRole, Object.assign(role, {id:roleId}), {}, ()=>this.getDocCrRolesOfPrjt())
			}else {
				this.req2Data(REQ.addDocCrRole, Object.assign(role, {prjtId:pid}), {},()=>this.getDocCrRolesOfPrjt())
			}
			this.roleDlg = false
		},

		search() {
			this.$set(this,'filterWord2',this.filterWord1.toLowerCase())
		},

		sorted(propertyN){
			this.sortingProperty = propertyN
			let order = !this.order[propertyN]
			delete this.order[propertyN]
			this.$set(this.order,propertyN,order)
		},

		roleFilter(role){
			this.$set(this,'roleN',role)
		},

		statusFilter(status){
			this.$set(this,'docStatus',status)
		},

		typeFilter(type){
			this.$set(this,'docType',type)
		},

		deptFilter(dept){
			this.$set(this,'dept',dept)
		},

		batchSelection(){
			let {allSeled, isIndeterminate, displayDocs} = this
			let s = !(isIndeterminate || allSeled)
			displayDocs.forEach(it => this.$set(this.checks, it.id, s))
		},

		getMyPrjts(){
			this.req2Data(REQ.myManagedDocPrjts,{},'myPrjts',()=>this.switchPrjt = true)
		},

		transferDoc(target){
			let docIds=[]
			let {checks, targetPrjtId, displayDocs} = this
			displayDocs.forEach(it=> {
				if (checks[it.id]) docIds.push(it.id)
			})
			if(this.chk2msg(docIds.length>0, '请选择要转移的文档')) return
			if(targetPrjtId) {
				this.req2Data(REQ.chgPrjtForDocs,{prjtId:targetPrjtId,docIds},{}, ()=>this.getDocPrjt())
				this.switchPrjt = false
			}else {
				this.req2Data(REQ.chgDeptForDocs,{deptId:target.id,docIds},{}, ()=>this.getDocPrjt())
			}
			this.isBatch=false
		},

		batchAddDocStaff(staff){
			let docIds=[]
			let {checks, displayDocs} = this
			let {staffId, role} = staff
			displayDocs.forEach(it=> {
				if (checks[it.id]) docIds.push(it.id)
			})
			if(this.chk2msg(docIds.length>0, '请选择要分配角色的文档')) return
			let num = docIds.length
			docIds.forEach(it=>{
				this.req2Data(REQ.addDocStaff, {docId: it, staffId, role,}, {} ,()=> {
					num--
					if(num <= 0) this.getDocPrjt()
				})
			})
			this.isBatch=false
		},

		preview(url){
			window.open(wordViewUrl + encodeURIComponent(url))
		},

		editAnnex(annexe){
			this.annexe = annexe
			this.showDlg('editAnnexDlg')
		},

		editDocDept(docDept){
			this.docDept = docDept
			this.showDlg('editDocDeptDlg')
		},

		deleteDocDept(id){
			this.confirm('该操作将会删除此归口部门，确认吗？',()=>{
				this.req2Data(REQ.rmDocDept, {id},{}, ()=>this.getDocPrjt())
			})
		},

		deleteAnnex(id){
			this.confirm('该操作将会删除此支持文件，确认吗？',()=>{
				this.req2Data(REQ.rmDocAnnex, {id},{}, ()=>this.getDocPrjt())
			})
		},

		editDoc(oldDoc,type,typeN){
			this.oldDoc = oldDoc
			this.typeN = typeN
			this.type = type
			this.showDlg('editDocDlg')
		},

		removePrjtStaff(prjtStaffId) {
			this.req2Data(REQ.rmDocPrjtStaff, {prjtStaffId}, {}, () => this.getDocPrjt())
		},

		createDocCensus(docs){
			let docCensus = {sop: {}, policy: {}, manual: {}, annex: {}, file: {}, all:{},}
			let docState = invMapOf(DOC_STATE)
			for (let i in docCensus) {
				docCensus[i].total = 0
				for (let j in DOC_STATE) {
					docCensus[i][j] = 0
				}
			}
			docCensus.all.total = docs.length
			docs.forEach(doc => {
				docCensus[doc.type].total++
				docCensus[doc.type][docState[doc.state]]++
				docCensus.all[docState[doc.state]]++
			})
			return docCensus
		},

		prjtCensusExport() {
			let {pid} = this
			this.req2Data(REQ.docPrjt, {id: pid, report: 1}, {}, docPrjt => {
				let docs = docPrjt.docs
				const {export_json_to_many_excel} = require('@/vendor/Export2Excel');
				//工作簿数据
				let sheets = [
					{name: '汇总', th: ['序号', '文件类型', '总数量', '已定稿', '审阅中', '起草中',], data: [],},
					{name: '文档',
						th: ['序号', '文件编号', '文件名称', '归口部门', '文件类型', '关联文档列表', '关联附件列表', '版本号',
							'eSOP状态', '发布日期', '生效日期', '分发部门', '范围', '角色', '参考法规', '修订历史',],
						data: [],
					},
					{name: '附件', th: ['序号', '文件编号', '文件名称', '归口部门', '文件类型', '关联文档列表', '版本号', 'eSOP状态', '发布日期', '生效日期',], data: [],},
				]
				//需求的属性名称
				let filterVal = [
					['numb', 'typeN', 'total', 'fixed', 'remarking', 'draft'],
					['numb', 'num', 'name', 'deptN', 'typeN', 'refFlow', 'annex', 'ver', 'stateN',
						'issueDate', 'sDate', 'deptsRecv', 'scope', 'duty', 'refLaw', 'history',],
					['numb', 'num', 'name', 'deptN', 'typeN', 'refFlow', 'ver', 'stateN', 'issueDate', 'sDate',],
				];
				let docData = [[], [], [],] //各工作表数据
				//文档统计表数据获取
				upWFoundById(docs, docPrjt.depts, 'deptId', (doc, dept)=> doc.deptN = dept.name)
				docs.forEach(doc=>{
					if(!doc.type) doc.type = DOC_TYPE.sop
					if(!doc.state) doc.state = DOC_STATE.remarking
					doc.typeN = doc.num.substring(0, doc.num.indexOf("-")) || doc.num
					doc.stateN = DocStatesSMap[doc.state || DOC_STATE.remarking]
					if(doc.content){
						let content = doc.content
						doc.refFlow = content.refFlows?.map(it=>it.num + ' ' + it.name).join(' \n') || ''
						doc.annex = content.annexes?.map(it=>it.num + ' ' + it.name).join(' \n') || ''
						doc.duty = content.duties?.map(it=>it.role).join('\n') || ''
						doc.scope = content.scopes?.map(it => it.name + '\n' + it.des).join(' \n') || ''
						doc.refLaw = content.refLaws?.map(it => it.name + '\n' + it.issuer + ' \n' + it.num + '\n' + it.date).join(' \n') || ''
						doc.history = content.histories?.map(it => it.note + it.ver).join(' \n') || ''
						doc.issueDate = content.issueDate
						doc.sDate = content.sDate
						doc.deptsRecv = content.deptsRecv
					}
				})
				//各类型文档数据获取
				let typeClassify = classify(docs, doc=>doc.typeN)
				typeClassify.all = docs
				let idx = 0
				for (let i in typeClassify) {
					let stateClassify = classify(typeClassify[i], doc=>doc.state)
					docData[0][idx] = {total:typeClassify[i].length, typeN:i == 'all' ? '':i, numb:i == 'all' ? '总计':idx+1}
					for (let j in DOC_STATE) {
						docData[0][idx][j] = stateClassify[DOC_STATE[j]]?.length || 0
					}
					idx++
				}
				docData[1] = docs.filter(doc => doc.type == DOC_TYPE.sop || doc.type == DOC_TYPE.policy || doc.type == DOC_TYPE.manual)
				docData[1].forEach((doc, idex) => doc.numb = idex + 1)
				docData[2] = docs.filter(doc => doc.type == DOC_TYPE.annex || doc.type == DOC_TYPE.file)
				docData[2].forEach((doc, idex) => {
					let refFlows = docData[1].filter(it => (doc.type == DOC_TYPE.file ? it.content?.refFlows : it.content?.annexes)?.find(item => item.refId && item.refId == doc.id))
					doc.refFlow = refFlows.map(i => i.num + ' ' + i.name).join('\n') || ''
					doc.issueDate = refFlows[0]?.content.issueDate || ''
					doc.sDate = refFlows[0]?.content.sDate || ''
					doc.numb = idex + 1
				})
				//提取excel表所需的各项数据
				sheets.forEach((sheet, idx) => sheet.data = docData[idx].map(v => filterVal[idx].map(j => v[j])))
				//生成excel文件
				export_json_to_many_excel(`项目统计`, sheets);
			})
		},

        prjtDocExport(typeN, stateN) {
            const {export_json_to_excel} = require('@/vendor/Export2Excel');
            const tHeader = ['文档编号', '中文名称', '类型(' + typeN + ')', '状态(' + stateN + ')','归口部门'];
            const filterVal = ['num', 'name','type', 'state','deptN'];
            const data = this.displayDocs.map(doc=> {
                let {...doc2} = doc
                doc2.type = this.DocTypesSMap[doc.type]
                doc2.state = this.DocStatesSMap[doc.state]
                return doc2
            }).map(v => filterVal.map(j => v[j]))
            export_json_to_excel(tHeader, data, `项目文档`);
        },
	},
};
